import './orders.css';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button, Collapse, List, Select, DatePicker } from 'antd';
import { SpinnerPage } from '../components/SpinnerPage';
import { showNotification } from '../customNotifications';

import { CloseOutlined } from '@ant-design/icons';

import {
  OrderLabel,
  OrderDetails,
  StatusFilterLabel,
} from '../components/order/orderSubComponents';

import dayjs from 'dayjs';

import {
  getDateComponentLocale,
  ddmmyyyyToyyyymmdd,
} from '../utils/orderUtils';

import { useTranslation } from 'react-i18next';
import { useApi } from '../providers/ServerApiProvider';

import { observer } from 'mobx-react-lite';
import { toolBoxStore } from '../store/store';

import { normalizeStringForSearch } from '../utils/misc';

export type Order = {
  id: number;
  guid: string;
  created_at: string;
  creation_datetime: string;
  parameters: {
    __type__: string;
    operation_name: string;
    calc_for: null;
    toolbox_host: string;
    storage_engine: string;
    inputs: {
      [key: string]: any; // You might want to define a more specific type for inputs
    };
  };
  status: string;
  priority: number;
  results: {
    [key: string]: any; // You might want to define a more specific type for results
  };
  avral_error?: string; // Optional property
};

const { RangePicker } = DatePicker;

const normalizeOrders = (
  orders: Order[],
  selectedIds: number[],
  handleOnChange?: (id: number, selectedIds: number[]) => void
) => {
  const ordersItems = orders.map((order) => {
    const orderItem = {
      key: order.id,
      guid: order.guid,
      label: <OrderLabel order={order} />,
      children: <OrderDetails order={order} />,
    };

    // in fact handles if order collapse is opened or not
    const getActiveKey = () =>
      orders.length === 1 ||
      (selectedIds.length > 0 && selectedIds.includes(orderItem.key))
        ? orderItem.key
        : undefined;

    return (
      <Collapse
        activeKey={getActiveKey()}
        style={{ width: '100%' }}
        items={[orderItem]}
        onChange={() => {
          if (handleOnChange) handleOnChange(orderItem.key, selectedIds);
        }}
      ></Collapse>
    );
  });

  return ordersItems;
};

const Orders: React.FC = observer(() => {
  const { t, i18n } = useTranslation();
  const serverApi = useApi();

  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState<Order[]>([]);

  const [currentPage, setCurrentPage] = useState<number>(
    Number(searchParams.get('page')) || 1
  );

  const [pageSize, setPageSize] = useState(
    Number(searchParams.get('pagesize')) || 10
  );

  const [toolFilter, setToolFilter] = useState<string>(
    searchParams.get('tool') || ''
  );

  const [statusFilter, setStatusFilter] = useState<string>(
    searchParams.get('status') || ''
  );

  const [startDateRangeFilter, setStartDateRangeFilter] = useState<string>(
    searchParams.get('startdate') || ''
  );

  const [endDateRangeFilter, setEndDateRangeFilter] = useState<string>(
    searchParams.get('enddate') || ''
  );

  const selectedIds =
    searchParams.get('selected')?.split(',').map(Number) || [];

  const toggleOpenedOrder = (orderId: number, selectedIds: number[]) => {
    if (selectedIds.includes(orderId)) {
      const newSelectedIds = selectedIds.filter((id) => id !== orderId);
      setSearchParams((params) => {
        if (newSelectedIds.length > 0) {
          params.set('selected', newSelectedIds.join(','));
        } else {
          params.delete('selected');
        }
        return params;
      });
    } else {
      const newSelectedIds = [...selectedIds, orderId];
      setSearchParams((params) => {
        params.set('selected', newSelectedIds.join(','));
        return params;
      });
    }
  };

  const statusFilterOptions = [
    { value: 'all', label: <StatusFilterLabel status="all" /> },
    { value: 'SUCCESS', label: <StatusFilterLabel status="SUCCESS" /> },
    { value: 'FAILED', label: <StatusFilterLabel status="FAILED" /> },
    { value: 'DENIED', label: <StatusFilterLabel status="DENIED" /> },
    { value: 'ACCEPTED', label: <StatusFilterLabel status="ACCEPTED" /> },
    { value: 'STARTED', label: <StatusFilterLabel status="STARTED" /> },
  ];

  useEffect(() => {
    setPageSize(Number(searchParams.get('pagesize')) || 10);
    setCurrentPage(Number(searchParams.get('page')) || 1);
    setToolFilter(searchParams.get('tool') || '');
    setStatusFilter(searchParams.get('status') || '');
    setStartDateRangeFilter(searchParams.get('startdate') || '');
    setEndDateRangeFilter(searchParams.get('enddate') || '');

    const fetchOrders = async () => {
      try {
        const resp = await serverApi.getOrders({
          params: { 'page[limit]': 1000 },
        });

        if (!resp.data.data) {
          console.log('No valid data in response: ', resp);
          showNotification(t('orders.otherError'), '', CloseOutlined);
          setOrders([]);
        } else {
          const orders = resp.data.data;

          if (searchParams.has('guid')) {
            const guidSearch = searchParams.get('guid');
            const guidSelectedOrders = orders.filter(
              (order: Order) => order.guid === guidSearch
            );
            setOrders(guidSelectedOrders);
          } else {
            const filteredOrders = orders.filter((order: Order) => {
              let passesToolFilter = true;
              let passesDateFilter = true;
              let passesStatusFilter = true;

              if (toolFilter) {
                passesToolFilter =
                  order?.parameters?.operation_name === toolFilter;
              }

              if (statusFilter) {
                passesStatusFilter =
                  order.status === statusFilter || statusFilter === 'all';
              }

              if (startDateRangeFilter && endDateRangeFilter) {
                const start = new Date(
                  ddmmyyyyToyyyymmdd(startDateRangeFilter)
                );
                const end = new Date(ddmmyyyyToyyyymmdd(endDateRangeFilter));
                const orderDate = new Date(order.created_at);
                passesDateFilter = orderDate > start && orderDate < end;
              }

              return passesToolFilter && passesDateFilter && passesStatusFilter;
            });
            setOrders(filteredOrders);
          }
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        showNotification(t('orders.otherError'));
        setLoading(false);
      }
    };

    fetchOrders();
    const updateOrdersTimeout = setInterval(fetchOrders, 5000);
    return () => {
      clearTimeout(updateOrdersTimeout);
    };
  }, [
    endDateRangeFilter,
    i18n.language,
    searchParams,
    serverApi,
    startDateRangeFilter,
    statusFilter,
    t,
    toolFilter,
  ]);

  const handleSelectToolsChange = (value: string) => {
    setToolFilter(value);
    setSearchParams((params) => {
      if (value) {
        params.set('tool', value);
        params.delete('page');
        return params;
      } else {
        params.delete('tool');
        params.delete('page');
        return params;
      }
    });
  };

  const handleDateRangeChange = (_dateObj: any, dateStringArr: string[]) => {
    setStartDateRangeFilter(dateStringArr[0]);
    setEndDateRangeFilter(dateStringArr[1]);
    setSearchParams((params) => {
      if (dateStringArr.every((el) => el !== '')) {
        params.set('startdate', dateStringArr[0]);
        params.set('enddate', dateStringArr[1]);
        params.delete('page');
        return params;
      } else {
        params.delete('startdate');
        params.delete('enddate');
        params.delete('page');
        return params;
      }
    });
  };

  const handleStatusFilterChange = (status: string) => {
    setStatusFilter(status);
    setSearchParams((params) => {
      if (status) {
        params.set('status', status);
        params.delete('page');
        params.delete('selected');
        return params;
      } else {
        params.delete('status');
        params.delete('page');
        params.delete('selected');
        return params;
      }
    });
  };

  const handlePaginationChange = (page: number, newPageSize: number) => {
    if (page !== currentPage) {
      setSearchParams((params) => {
        params.delete('selected');
        return params;
      });
      setSearchParams((params) => {
        params.set('page', String(page));
        return params;
      });
    }

    if (pageSize !== newPageSize) {
      setPageSize(newPageSize);
      setSearchParams((params) => {
        params.set('page', '1');
        if (newPageSize) {
          params.set('pagesize', String(newPageSize));
          return params;
        } else {
          params.delete('pagesize');
          return params;
        }
      });
    }
  };

  const handleReset = () => {
    setSearchParams((params) => {
      params.delete('selected');
      params.delete('startdate');
      params.delete('enddate');
      params.delete('tool');
      params.delete('status');
      params.delete('page');
      params.delete('guid');
      return params;
    });
    setToolFilter('');
    setToolFilter('');
    setStartDateRangeFilter('');
    setEndDateRangeFilter('');
  };

  const filterToolOptions = (
    input: string,
    option?: { label: string; value: string }
  ): boolean => {
    const normalizedInput = normalizeStringForSearch(input);

    const searchText = normalizeStringForSearch(
      (option?.label ?? '') + (option?.value ?? '')
    );
    const normalizedSearchText = normalizeStringForSearch(searchText);

    return normalizedSearchText.includes(normalizedInput);
  };

  return (
    <div
      style={{
        height: '100%',
        padding: '40px 20px 20px 20px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <div style={{ flexGrow: 0.8, maxWidth: '1100px' }}>
        <h1
          style={{
            lineHeight: 1.6,
            marginTop: 0,
            fontSize: 34,
            marginBottom: '8px',
          }}
        >
          {t('orders.title')}
        </h1>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            padding: '10px',
            marginBottom: '5px',
            borderRadius: '10px',
            backgroundColor: 'rgb(245, 245, 245)',
          }}
        >
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexWrap: 'wrap',
              gap: '12px',
            }}
          >
            <Select
              allowClear
              showSearch
              value={toolFilter || null}
              style={{ width: '100%', maxWidth: '440px' }}
              placeholder={t('orders.select_by_tool')}
              onChange={handleSelectToolsChange}
              options={toolBoxStore.tools.map((tool) => ({
                value: tool.operation_id,
                label: tool.name,
              }))}
              filterOption={filterToolOptions}
            />
            <RangePicker
              format={'DD-MM-YYYY'}
              locale={getDateComponentLocale(i18n.language)}
              onChange={handleDateRangeChange}
              value={
                startDateRangeFilter && endDateRangeFilter
                  ? [
                      dayjs(ddmmyyyyToyyyymmdd(startDateRangeFilter)),
                      dayjs(ddmmyyyyToyyyymmdd(endDateRangeFilter)),
                    ]
                  : undefined
              }
            />
            <Select
              allowClear
              style={{ width: '100%', maxWidth: '160px' }}
              placeholder={t('orders.select_options.placeholder')}
              value={statusFilter || null}
              options={statusFilterOptions}
              onChange={handleStatusFilterChange}
            />
            <div
              style={{
                display: 'flex',
                flexGrow: 1,
                justifyContent: 'flex-end',
              }}
            >
              <Button
                style={{ alignSelf: 'flex-end' }}
                type="text"
                onClick={handleReset}
              >
                {t('orders.filters_reset')}
              </Button>
            </div>
          </div>
        </div>
        {loading ? (
          <SpinnerPage />
        ) : (
          <List
            pagination={{
              locale: { items_per_page: t('orders.items_per_page') },
              position: 'top',
              current: currentPage,
              pageSize: pageSize,
              onChange: handlePaginationChange,
              showSizeChanger: true,
            }}
            split={false}
            dataSource={normalizeOrders(orders, selectedIds, toggleOpenedOrder)}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          ></List>
        )}
      </div>
    </div>
  );
});

export { Orders };
