import React, { useState, ReactNode, createContext, useContext } from 'react';
import axios from 'axios';
import { loginUrl, profileUrl, getTokenUrl } from '../routes/apiRoutes';
import { toolBoxStore } from '../store/store';

interface AuthContextProps {
  loggedIn: boolean;
  logIn: () => Promise<void>;
  logOut: () => void;
  setLoggedIn: React.Dispatch<React.SetStateAction<boolean>>;
  isLoggedIn: () => boolean;
  getAuthToken: () => string | null;
  getProfile: () => Promise<any>;
}

type AuthProviderProps = {
  children: ReactNode;
};

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);

  const isLoggedIn = () => {
    const authData = localStorage.getItem('authToken');
    return !!authData;
  };

  const logIn = async () => {
    try {
      if (process.env.NODE_ENV === 'production') {
        const res = await axios.get(getTokenUrl);
        const token = res.data.token;
        if (token) {
          localStorage.setItem('authToken', JSON.stringify({ token }));
          setLoggedIn(true);
          localStorage.setItem('beenHere', '1');
        }
      }

      if (process.env.NODE_ENV === 'development') {
        const res = await axios.get(loginUrl);
        const token = res.data.token;
        if (token) {
          localStorage.setItem('authToken', JSON.stringify({ token }));
          setLoggedIn(true);
          localStorage.setItem('beenHere', '1');
        }
      }
    } catch (err) {
      setLoggedIn(false);
      throw err; // error traveling magic!!!
    }
  };

  const getAuthToken = (): string | null => {
    const tokenData = localStorage.getItem('authToken');
    if (tokenData) {
      return JSON.parse(tokenData).token;
    }
    return null;
  };

  const getProfile = async () => {
    // console.log('get profile token', getAuthToken());
    try {
      const res = await axios.get(profileUrl, {
        headers: {
          Authorization: `Token ${getAuthToken()}`,
        },
      });
      return res;
    } catch (error) {
      console.log(
        'Could not send request, probably broken token or other weird error. Attempting to relogin.'
      );
      logOut();
      await logIn();
    }
  };

  const logOut = () => {
    localStorage.removeItem('authToken');
    toolBoxStore.resetUser();
    setLoggedIn(false);
  };

  const authAPI = {
    loggedIn,
    logIn,
    logOut,
    setLoggedIn,
    isLoggedIn,
    getAuthToken,
    getProfile,
  };

  return (
    <AuthContext.Provider value={authAPI}>{children}</AuthContext.Provider>
  );
};

//@ts-ignore
export const AuthContext = createContext<AuthContextProps>(AuthProvider);
export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
