import React, { useEffect, useState } from 'react';

import { Layout, ConfigProvider } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { showNotification } from './customNotifications';

import { Main } from './pages/Main';
import { About } from './pages/About';
import { Operation } from './pages/Operation';
import { Pricing } from './pages/Pricing';
import { Orders } from './pages/Orders';
import { NotFound } from './pages/NotFound';
import { FooterContent } from './components/FooterContent';
import { HeaderContent } from './components/HeaderContent';
import { NoData } from './components/NoData';

import { Routes, Route, useLocation, Navigate, Outlet } from 'react-router-dom';

import { appRoutes } from './routes/appRoutes';

import { useAuth } from './providers/AuthProvider';
import { useApi } from './providers/ServerApiProvider';

import { sendYmEvent } from './utils/metrics.js';

import { toolBoxStore } from './store/store';
import { SpinnerPage } from './components/SpinnerPage';

import { useTranslation } from 'react-i18next';

import { initChatra, updateChatraUser } from './utils/chatraService';
import { supportedLocales } from './config';

const { Header, Content, Footer } = Layout;

const ProtectedRoute = () => {
  const auth = useAuth();
  return auth.isLoggedIn() ? (
    <Outlet />
  ) : (
    <Navigate to={appRoutes.mainPage} replace />
  );
};

const View: React.FC = () => {
  const auth = useAuth();
  const serverApi = useApi();
  const { t, i18n } = useTranslation();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loginOnLoad() {
      try {
        if (!auth.isLoggedIn()) {
          if (process.env.NODE_ENV === 'production') {
            try {
              auth.logIn();
              processUserProfile();
            } catch (error) {
              console.log('not logged in but maybe its ok');
            }
            return;
          }
        } else if (auth.isLoggedIn()) {
          processUserProfile();
          if (toolBoxStore.deployMode !== 'onpremise') {
            sendYmEvent('authed_user_visit');
          }
        }
      } catch (error) {
        console.error(
          'An error occurred during login and profile retrieval:',
          error
        );
      }
    }

    async function processUserProfile() {
      try {
        const resp = await auth.getProfile();
        if (!resp.data.is_authenticated) {
          auth.logOut();
          await auth.logIn();
        } else if (resp.data.username) {
          const {
            username,
            email,
            // first_name,
            // last_name,
            is_privileged,
          } = resp.data;

          toolBoxStore.setUserData({
            username,
            email,
            // firstName: first_name,
            // lastName: last_name,
            isPremium: !!is_privileged,
          });

          if (toolBoxStore.deployMode !== 'onpremise') {
            updateChatraUser(username, email);
          }

          if (i18n.language !== resp.data.locale) {
            i18n.changeLanguage(resp.data.locale);
          }
        } else {
          console.warn('Could not get username, response is: ', resp);
        }
      } catch (error) {
        console.error(
          'An error occurred while processing the user profile:',
          error
        );
      }
    }

    async function fetchData() {
      try {
        const respTools = await serverApi.getToolsList();
        const respTags = await serverApi.getTagsList();

        if (!respTags.data.data || !respTools.data.data) {
          showNotification(t('main.otherError'), '', CloseOutlined);
          console.log('wrong startup data!');
        }

        const toolsFromApi = respTools.data.data;
        toolBoxStore.updateToolsList(toolsFromApi);

        const tags = respTags.data.data;
        toolBoxStore.updateTagsList(tags);

        setLoading(false);
      } catch (error) {
        console.log('error loadind startup data', error);
        showNotification(t('main.otherError'), '', CloseOutlined);
        setLoading(false);
      }
    }

    if (toolBoxStore.deployMode !== 'onpremise') {
      const locale = supportedLocales.includes(i18n.language)
        ? i18n.language
        : 'en';

      initChatra(locale);
    }

    loginOnLoad();
    fetchData();
  }, [auth, i18n, serverApi, t]);

  const location = useLocation();
  const isPageWithTab = () => {
    return (
      location.pathname === appRoutes.ordersPage ||
      !Object.values(appRoutes).includes(location.pathname)
    );
  };

  return (
    <ConfigProvider
      renderEmpty={() => <NoData />}
      theme={{
        token: {
          fontFamily: 'Ubuntu, Roboto, Helvetica, Arial, sans-serif',
          colorBgLayout: '#ffffff',
          colorLink: '#0070c5',
          colorPrimary: '#0070c5',
        },
        components: {
          Layout: {
            headerHeight: 52,
            headerPadding: '0 0 0 18px',
            footerBg: '#2d2d2d',
            footerPadding: 8,
          },
          Tabs: {
            lineType: 'none', // to implement own bottom line on tabs
            itemSelectedColor: '#212121',
            itemColor: '#212121',
            itemHoverColor: '#0070c5',
            inkBarColor: isPageWithTab() ? 'transparent' : '#0070c5',
          },
          Card: {
            headerFontSize: 22,
            fontSize: 16,
          },
          List: {
            itemPadding: '4px 0px 4px 0px',
          },
          Tag: {
            defaultColor: '#212121',
          },
          Carousel: {
            dotHeight: 5,
            dotActiveWidth: 64,
            dotWidth: 20,
          },
        },
      }}
    >
      <Layout
        style={{
          // minHeight: '100vh', // creepy but works for now
          height: '100%',
        }}
      >
        <Header
          style={{
            position: 'fixed',
            left: 0,
            right: 0,
            top: 0,
            zIndex: 1000,
            display: 'flex',
            alignItems: 'flex-end',
            gap: '50px',
            backgroundRepeat: 'no-repeat',
            background: `linear-gradient(to bottom, #e5eef7 98%, #d3e3f2 2%)`,
          }}
        >
          <HeaderContent />
        </Header>
        <Content
          style={{
            marginTop: '52px',
            marginBottom:
              toolBoxStore.appMode.deploy === 'cloud' ? '36px' : undefined,
            height: '100%',
          }}
        >
          <Routes>
            <Route
              path={appRoutes.mainPage}
              element={loading ? <SpinnerPage /> : <Main />}
            />
            <Route path={appRoutes.operation}>
              <Route path="" element={<Navigate to={appRoutes.mainPage} />} />
              <Route path=":toolId" element={<Operation />} />
            </Route>
            {toolBoxStore.deployMode === 'cloud' && (
              <>
                <Route path={appRoutes.aboutPage} element={<About />} />
                <Route path={appRoutes.pricingPage} element={<Pricing />} />
              </>
            )}
            <Route path={appRoutes.ordersPage} element={<ProtectedRoute />}>
              <Route path="" element={<Orders />} />
            </Route>
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Content>
        {toolBoxStore.appMode.deploy === 'cloud' && (
          <Footer
            style={{
              color: 'rgba(255, 255, 255, 0.4)',
              zIndex: '10000',
              position: 'fixed',
              bottom: 0,
              width: '100%',
            }}
          >
            <FooterContent />
          </Footer>
        )}
      </Layout>
    </ConfigProvider>
  );
};

export default View;
