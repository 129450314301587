import '../../operationForm.css';
import React from 'react';

import { FormInstance, Input } from 'antd';

import type { toolInput } from '../../../interfaces';

const NumberInput: React.FC<{
  input: toolInput;
  form: FormInstance<any>;
  onValueChange?: Function;
}> = ({ input, form, onValueChange }) => (
  <Input
    name={input.name}
    value={form.getFieldValue(input.name)}
    placeholder={''}
    allowClear
    autoComplete="on"
    onChange={(e) => {
      form.setFieldValue(input.name, e.target.value);
      if (onValueChange) {
        onValueChange(input.name);
      }
    }}
    style={{
      maxWidth: '600px',
    }}
  />
);

export { NumberInput };
