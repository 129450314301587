import '../../operationForm.css';
import React, { useEffect, useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { Button, FormInstance, Upload, UploadFile, UploadProps } from 'antd';

import { CloseCircleOutlined } from '@ant-design/icons';

import { useApi } from '../../../providers/ServerApiProvider';
import { useTranslation } from 'react-i18next';

import type { toolInput } from '../../../interfaces';

const { Dragger } = Upload;

const UploadInput: React.FC<{
  input: toolInput;
  form: FormInstance<any>;
  setPending: (value: boolean) => void;
  onValueChange?: Function;
}> = ({ input, form, setPending, onValueChange }) => {
  // IF fileId is in searchParams

  const { t } = useTranslation();
  const serverApi = useApi();

  const [searchParams] = useSearchParams();

  const [fileList, setFileList] = useState<UploadFile<any>[]>([]);

  const [preFilled, setPreFilled] = useState(searchParams.has(input.name));

  useEffect(() => {
    if (searchParams.has(input.name)) {
      setPreFilled(true);
    } else {
      setPreFilled(false);
    }
  }, [searchParams, input.name]);

  const uploadFile = async (options: any) => {
    const { onSuccess, onError, onProgress, file } = options;

    const config = {
      onUploadProgress: (event: any) => {
        onProgress({ percent: Math.floor((event.loaded / event.total) * 100) });
      },
    };

    try {
      setPending(true);
      const res = await serverApi.postUploadFile(file, config);
      const fileId = res.data;
      form.setFieldValue(input.name, fileId);
      onSuccess('ok');
      setPending(false);
    } catch (err) {
      console.log('error: ', err);
      // const error = new Error('some error');
      onError({ err });
    }
  };

  const handleOnChange = (changeEvent: any) => {
    if (changeEvent.file.status === 'removed') {
      form.setFieldValue(input.name, undefined);
      setFileList([]);
    } else {
      setFileList([changeEvent.file]);
    }
  };

  const props: UploadProps = {
    progress: {
      strokeColor: {
        '0%': '#87d068',
        '100%': '#87d068',
      },
    },
  };

  return preFilled && !!form.getFieldValue(input.name) ? (
    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      <div>
        <span>{t('operation.fileId')}</span>
        <span style={{ color: '#545454' }}>
          {form.getFieldValue(input.name)}
        </span>
      </div>
      <Button
        onClick={() => {
          form.setFieldValue(input.name, undefined);
          setPreFilled(false);
          if (onValueChange) {
            onValueChange(input.name);
          }
        }}
        type="primary"
        size="small"
        icon={<CloseCircleOutlined />}
      ></Button>
    </div>
  ) : (
    <Dragger
      {...props}
      customRequest={uploadFile}
      onChange={handleOnChange}
      name={input.name}
      fileList={fileList}
    >
      <a>{t('operation.uploadSelect')}</a>{' '}
      <span>{t('operation.uploadDnDsuggestion')}</span>
    </Dragger>
  );
};

export { UploadInput };
