import { ToolInfo } from './store/store';

export type normalizedToolInfo = {
  id: number;
  operation_id: string;
  name: string;
  description: string;
  link: string;
  is_dev: boolean;
  is_free?: boolean;
  tags: number[];
};

export const normalizeToolData = (tool: ToolInfo): normalizedToolInfo => {
  const linkRegex = /<a\s+(?:[^>]*?\s+)?href=["']([^"']*)["']/;

  const description = tool.description
    .replace(/<[^>]*>/g, '')
    .trim()
    .replace(/Подробнее$|Подробнее\.$|Details$|More info$/, '')
    .trim();

  const linkMatch = tool.description.match(linkRegex);

  return { ...tool, description, link: linkMatch?.at(1) || '' };
};
