import React, { useEffect, useState } from 'react';
import { Avatar, Button, Dropdown, MenuProps, Grid } from 'antd';
import {
  CaretDownOutlined,
  UserOutlined,
  LogoutOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';

import './userBlock.css';
import { useLocation, useNavigate } from 'react-router';
import { appRoutes } from '../routes/appRoutes';
import { cloudAuthProfileUrl } from '../routes/apiRoutes';

import { observer } from 'mobx-react-lite';
import { toolBoxStore } from '../store/store';

import { useTranslation } from 'react-i18next';
import { useAuth } from '../providers/AuthProvider';
import { useApi } from '../providers/ServerApiProvider';
import { getAvatarUrl } from '../utils/misc';

const { useBreakpoint } = Grid;

const LoginBtn: React.FC = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  const { getLoginRedirectUrl } = useApi();

  const isProd = process.env.NODE_ENV === 'production';

  const location = useLocation();

  return isProd ? (
    <Button type="link" href={getLoginRedirectUrl(location.pathname)}>
      <span style={{ fontWeight: 500, color: '' }}>
        {t('navigation.login')}
      </span>
    </Button>
  ) : (
    <Button type="link" onClick={() => auth.logIn()}>
      {/* <Button type="link" href={getLoginRedirectUrl(location.pathname)}> */}
      <span style={{ fontWeight: 500, color: '' }}>
        {t('navigation.login')}
      </span>
    </Button>
  );
};

const Profile: React.FC = observer(() => {
  const { t } = useTranslation();
  const auth = useAuth();
  const navigate = useNavigate();
  const { getLogoutRedirectUrl } = useApi();
  const [gravatarUrl, setGravatarUrl] = useState<string | undefined>();

  const isProd = process.env.NODE_ENV === 'production';

  const username = toolBoxStore.userName;

  useEffect(() => {
    const fetchAvatar = async () => {
      const { email } = toolBoxStore.user;

      if (email) {
        const url = await getAvatarUrl(email);
        if (url) {
          setGravatarUrl(url);
        }
      }
    };
    fetchAvatar();
  }, []);

  const profileMenuItem = {
    label: (
      <a
        className="a-profile-menu-item"
        target="_blank"
        rel="noopener noreferrer"
        href={cloudAuthProfileUrl}
      >
        {/* {gravatar? <img></img>} */}
        <UserOutlined style={{ fontSize: 16 }} /> &nbsp;&nbsp;{' '}
        {t('navigation.profile')}
      </a>
    ),
    key: '0',
  };

  const logoutMenuItem = {
    label: isProd ? (
      <Button
        className="a-profile-menu-item"
        rel="noopener noreferrer"
        type="link"
        href={getLogoutRedirectUrl()}
        onClick={() => {
          auth.logOut();
        }}
      >
        <LogoutOutlined style={{ fontSize: 16 }} /> &nbsp;&nbsp;{' '}
        {t('navigation.logout')}
      </Button>
    ) : (
      <Button
        className="a-profile-menu-item"
        rel="noopener noreferrer"
        type="link"
        href={getLogoutRedirectUrl()}
        onClick={() => {
          auth.logOut();
          navigate(appRoutes.mainPage);
        }}
      >
        <LogoutOutlined style={{ fontSize: 16 }} /> &nbsp;&nbsp;{' '}
        {t('navigation.logout')}
      </Button>
    ),
    key: '1',
  };

  const profileDropDownItems: MenuProps['items'] =
    toolBoxStore.deployMode === 'onpremise'
      ? [logoutMenuItem]
      : [profileMenuItem, logoutMenuItem];

  const screens = useBreakpoint();
  return (
    <Dropdown
      overlayStyle={{ zIndex: 10000 }}
      menu={{ items: profileDropDownItems }}
      arrow={true}
      trigger={['click']}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className="a-profile" onClick={(e) => e.preventDefault()}>
        <Avatar
          style={{ marginBottom: '3px' }}
          icon={
            gravatarUrl ? (
              <img src={gravatarUrl} alt="avatar" />
            ) : (
              <UserOutlined />
            )
          }
        />
        {screens.md && (
          <>
            &nbsp;&nbsp;{username} <CaretDownOutlined />
          </>
        )}
      </a>
    </Dropdown>
  );
});

const Tasks: React.FC<{ updated: boolean }> = () => {
  const navigate = useNavigate();
  const screens = useBreakpoint();
  const { t } = useTranslation();

  return (
    // <Badge dot={updated}>

    <a href={appRoutes.ordersPage} onClick={(e) => e.preventDefault()}>
      <Button
        onClick={() => {
          navigate(appRoutes.ordersPage);
        }}
        type="primary"
        icon={
          <UnorderedListOutlined
            style={{ fontSize: 16, position: 'relative', top: '1px' }}
          />
        }
      >
        {screens.sm && <span>{t('navigation.orders')}</span>}
      </Button>
    </a>

    // </Badge>
  );
};

const UserBlock: React.FC = () => {
  const auth = useAuth();

  return (
    <div
      style={{
        height: '100%',
        marginRight: '15px',
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        gap: '15px',
      }}
    >
      {auth.isLoggedIn() ? (
        <>
          <Tasks updated={false} /> <Profile />
        </>
      ) : (
        <LoginBtn />
      )}
    </div>
  );
};

export { UserBlock };
