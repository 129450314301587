import React from 'react';
import { Empty } from 'antd';

import { useTranslation } from 'react-i18next';

const NoData: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('noData')} />
  );
};

export { NoData };
