import ReactDOM from 'react-dom/client';

import { App as Antd } from 'antd';

import { BrowserRouter } from 'react-router-dom';

import i18next from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import resources from './locales';
import AuthProvider from './providers/AuthProvider';
import ServerApiProvider from './providers/ServerApiProvider';

import './index.css';
import View from './View';
import axios from 'axios';

import { profileUrl, configUrl } from './routes/apiRoutes';
import { toolBoxStore } from './store/store';

import { initYandexMetrics } from './utils/metrics';

import { initGoogleAnalytics } from './utils/metrics';

import { initSentry } from './utils/sentry';

const App = async () => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  let pinnedLocale;

  try {
    const respConfig = await axios.get(configUrl);
    pinnedLocale = respConfig.data.results.language_pinned;
    toolBoxStore.setAppConfig(respConfig.data.results);
  } catch (error) {
    console.log('error getting config', error);
  }

  const i18n = i18next.createInstance();
  await i18n.use(initReactI18next).init({
    // debug: true,
    resources,
    lng: pinnedLocale || 'en',
    fallbackLng: pinnedLocale || 'en',
  });

  const tokenData = localStorage.getItem('authToken');
  if (tokenData) {
    const token = JSON.parse(tokenData).token;
    try {
      const res = await axios.get(profileUrl, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      if (res.data.locale && Object.hasOwn(resources, res.data.locale)) {
        i18n.changeLanguage(res.data.locale);
      }

      if (res.data.username && res.data.is_privileged) {
        const { username, email, first_name, last_name, is_privileged } =
          res.data;

        toolBoxStore.setUserData({
          username,
          email,
          firstName: first_name,
          lastName: last_name,
          isPremium: !!is_privileged,
        });
      }
    } catch (error) {
      console.log('Could not get and handle locale set', error);
    }
  } else if (pinnedLocale === undefined) {
    try {
      const locale = navigator.language.split('-')[0];
      i18n.changeLanguage(locale);
    } catch (error) {
      console.log('navigator.language unexpected value', error);
    }
  }

  const isBeenHereLoggedIn = localStorage.getItem('beenHere');
  const showInfo = localStorage.getItem('showInfo');
  const userWantsInfo = showInfo || false;

  if (isBeenHereLoggedIn === '1' && !userWantsInfo) {
    localStorage.setItem('showInfo', JSON.stringify({ showInfo: false }));
  }

  if (toolBoxStore.deployMode !== 'onpremise') {
    initSentry();
    initYandexMetrics();
    initGoogleAnalytics();
  }

  console.log('1.3.1');

  root.render(
    <Antd>
      <AuthProvider>
        <ServerApiProvider>
          <I18nextProvider i18n={i18n}>
            <BrowserRouter>
              <View />
            </BrowserRouter>
          </I18nextProvider>
        </ServerApiProvider>
      </AuthProvider>
    </Antd>
  );
};

export default App;
