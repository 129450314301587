import localeRU from 'antd/es/date-picker/locale/ru_RU';
import localeEN from 'antd/es/date-picker/locale/en_US';

import 'dayjs/locale/ru';
import 'dayjs/locale/en-gb';

import type { PickerLocale } from 'antd/es/date-picker/generatePicker';

type localeMap = {
  [key: string]: PickerLocale;
};

const localeMapping: localeMap = {
  ru: localeRU,
  en: localeEN,
};

const getDateComponentLocale = (lang: string) => {
  if (localeMapping[lang]) {
    return localeMapping[lang];
  } else {
    return localeEN;
  }
};

const ddmmyyyyToyyyymmdd = (date: string) => {
  return date.split('-').reverse().join('/');
};

export { getDateComponentLocale, ddmmyyyyToyyyymmdd };
