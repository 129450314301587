import "./operationForm.css";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { getPricingUrl } from "../routes/apiRoutes";

import { appRoutes } from "../routes/appRoutes";

import { observer } from "mobx-react-lite";
import { ToolInfo, toolBoxStore } from "../store/store";

import { StringInput, NumberInput, BoolInput, UploadInput } from "./formInputs";

import { Button, Card, Form } from "antd";
import { notification } from "antd";

import {
  InfoCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import { useApi } from "../providers/ServerApiProvider";
import { useTranslation } from "react-i18next";

import type { toolInput } from "../interfaces";
import { useAuth } from "../providers/AuthProvider";

type NotificationType = "success" | "info" | "warning" | "error";

const notificationIconMapping: Record<NotificationType, JSX.Element> = {
  success: <CheckOutlined style={{ color: "green" }} />,
  error: <CloseOutlined style={{ color: "red" }} />,
  info: <InfoCircleOutlined />,
  warning: <InfoCircleOutlined />,
};

const getNotificationIcon = (type: NotificationType): JSX.Element => {
  const icon = notificationIconMapping[type];
  if (!icon) {
    console.warn("wrong notification type: ", type);
    return <InfoCircleOutlined />;
  }
  return icon;
};

const showOperationNotification = (
  message: string = "",
  description: string = "",
  type: NotificationType
) => {
  notification.info({
    type: type,
    message: message,
    description: description,
    placement: "topRight",
    icon: getNotificationIcon(type),
  });
};

const Extra: React.FC<{ text: string }> = ({ text }) => {
  const extra = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (extra.current) extra.current.innerHTML = text;
  });

  return (
    <div
      ref={extra}
      style={{
        padding: "6px",
        fontSize: 12,
        textAlign: "left",
        overflowWrap: "anywhere",
      }}
    >
      {text}
    </div>
  );
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const inputMapping = {
  string: StringInput,
  int: NumberInput,
  float: NumberInput,
  boolean: BoolInput,
  file: UploadInput,
};

const getInputComponent = (input: toolInput) => {
  const component = inputMapping[input.type];
  if (!component) {
    return StringInput;
  }
  return component;
};

const prepareStringToNumberParsing = (value: string) => {
  return value.replace(",", ".");
};

const normalizeFieldsValueseforeSubmit = (
  fieldsValues: any,
  inputs: toolInput[]
) => {
  const normalizedProps: any = {};

  // for now it processes only float
  Object.keys(fieldsValues).forEach((key: keyof typeof fieldsValues) => {
    const input = inputs.find((i: toolInput) => i.name === key);
    if (input?.name === key) {
      if (input.type === "float") {
        normalizedProps[key] = fieldsValues[key]
          ? prepareStringToNumberParsing(fieldsValues[key])
          : undefined;
      }
    }
  });
  return { ...fieldsValues, ...normalizedProps };
};

const OperationForm: React.FC<{
  inputs: toolInput[];
  toolId: string | undefined;
  // isEnabled?: boolean | undefined;
}> = observer(
  ({
    inputs,
    toolId,
    //  isEnabled
  }) => {
    const { t, i18n } = useTranslation();
    const auth = useAuth();
    const navigate = useNavigate();
    const serverApi = useApi();
    const [searchParams, setSearchParams] = useSearchParams();

    const [pending, setPending] = useState(false);

    const [runEnabled, setRunEnabled] = useState<boolean | undefined>(false);

    const { publicTools } = toolBoxStore;

    const toolData = publicTools.find(
      (i: ToolInfo) => i.operation_id === toolId
    );

    const [form] = Form.useForm();

    useEffect(() => {
      const isRunEnabled = toolData?.is_free
        ? true
        : toolBoxStore.isUserPremium || toolBoxStore.deployMode === "onpremise";
      setRunEnabled(isRunEnabled);

      const setInitialValue = (input: toolInput) => {
        if (searchParams.has(input.name)) {
          if (input.type === "boolean") {
            form.setFieldValue(
              input.name,
              searchParams.get(input.name) === "true"
            );
          } else {
            form.setFieldValue(input.name, searchParams.get(input.name));
          }
        } else {
          if (input.type === "boolean" && !form.getFieldValue(input.name)) {
            form.setFieldValue(input.name, false);
          }
        }
      };

      inputs.forEach(setInitialValue);
    }, [form, inputs, searchParams, toolData?.is_free]);

    form.submit = async () => {
      try {
        if (toolId) {
          setPending(true);

          const normalizedFormData = normalizeFieldsValueseforeSubmit(
            form.getFieldsValue(),
            inputs
          );

          await serverApi.postExecuteTool(toolId, normalizedFormData);
          setPending(false);
        }
        form.resetFields();
        navigate(appRoutes.ordersPage);

        //@ts-ignore
      } catch (error: Error | AxiosError) {
        setPending(false);
        console.log(error);
        const errorDescription = error.response
          ? error.response.data
          : t("operation.otherError");

        showOperationNotification(
          t("operation.executeError"),
          errorDescription,
          "error"
        );
      }
    };

    return (
      <>
        <Form
          form={form}
          disabled={!runEnabled}
          {...formItemLayout}
          name="trigger"
          layout="horizontal"
          labelWrap={true}
          autoComplete="on"
          style={{
            padding: "18px 12px 0px 12px",
            maxWidth: "1100px",
            marginTop: "16px",
            borderRadius: "5px",
          }}
        >
          {inputs &&
            inputs.map((input) => {
              const InputComponent = getInputComponent(input);
              return (
                <Form.Item
                  label={input.title}
                  name={input.name}
                  style={{
                    marginBottom: "4px",
                    maxWidth: "800px",
                  }}
                  key={input.name}
                  hasFeedback
                  validateTrigger="onBlur"
                  extra={<Extra text={input.description} />}
                >
                  <InputComponent
                    input={input}
                    form={form}
                    setPending={setPending}
                    onValueChange={(name: string) =>
                      setSearchParams((params) => {
                        params.delete(name);
                        return params;
                      })
                    }
                  />
                </Form.Item>
              );
            })}
          {runEnabled && auth.isLoggedIn() && (
            <Form.Item
              {...tailFormItemLayout}
              style={{
                marginTop: "8px",
              }}
            >
              <Button
                disabled={pending}
                icon={pending && <LoadingOutlined />}
                size="large"
                type="primary"
                htmlType="submit"
              >
                {t("operation.submit")}
              </Button>
            </Form.Item>
          )}

          {!runEnabled && toolBoxStore.deployMode !== "onpremise" && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a href={getPricingUrl(i18n.language)}>
                <Card
                  className="premium-card"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  <span style={{ whiteSpace: "pre-wrap" }}>
                    {t("operation.needPremium")}
                  </span>
                  <span className="premium-chip">Premium</span>
                </Card>
              </a>
            </div>
          )}

          {runEnabled &&
            !auth.isLoggedIn() &&
            toolBoxStore.deployMode !== "onpremise" && (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <a
                  href={serverApi.getLoginRedirectUrl(
                    `/${appRoutes.operation}/${toolId}`
                  )}
                >
                  <Card
                    className="premium-card"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    <span style={{ whiteSpace: "pre-wrap" }}>
                      {t("operation.needRegister")}
                    </span>
                  </Card>
                </a>
              </div>
            )}

          {!auth.isLoggedIn() && toolBoxStore.deployMode === "onpremise" && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a
                href={serverApi.getLoginRedirectUrl(
                  `${window.location.origin}/${appRoutes.operation}/${toolId}`
                )}
              >
                <Card
                  className="premium-card"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  <span style={{ whiteSpace: "pre-wrap" }}>
                    {t("operation.onpremiseNeedLogin")}
                  </span>
                </Card>
              </a>
            </div>
          )}
        </Form>
      </>
    );
  }
);

export { OperationForm };
