import './operation.css';

import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Button, Card, Radio, RadioChangeEvent, Tag, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { OperationForm } from '../components/OperationForm';
import { OperationCode } from '../components/OperationCode';
import { SpinnerPage } from '../components/SpinnerPage';

import { useNavigate, useParams } from 'react-router-dom';
import { appRoutes } from '../routes/appRoutes';

import { normalizeToolData } from '../helpers';
import { showNotification } from '../customNotifications';

import { useAuth } from '../providers/AuthProvider';
import { useApi } from '../providers/ServerApiProvider';

import { useTranslation } from 'react-i18next';
import { toolBoxStore } from '../store/store';
import { observer } from 'mobx-react-lite';

import type { ToolInfo } from '../store/store';
import type { normalizedToolInfo } from '../helpers';

import { sendYmEvent } from '../utils/metrics';

const placeholderTool: normalizedToolInfo = {
  id: 0,
  operation_id: '',
  name: '',
  description: '',
  link: '',
  is_dev: false,
  is_free: false,
  tags: [],
};

const Operation: React.FC = observer(() => {
  const { t } = useTranslation();
  const auth = useAuth();
  const serverApi = useApi();
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = useState(true);
  const [inputs, setInputs] = useState<any>([]);
  const [codeText, setCodeText] = useState<string>('');

  const [contentKey, setContentKey] = useState('form');

  const navigate = useNavigate();
  const { toolId } = useParams();
  const { publicTools, toolsCount, user } = toolBoxStore;

  const toolData = publicTools.find((i: ToolInfo) => i.operation_id === toolId);

  if (!toolData && toolsCount > 0) {
    navigate(appRoutes.other, { replace: true });
  }

  useEffect(() => {
    const fetchToolInnerData = async (id: string): Promise<void> => {
      if (
        toolData?.ngw_client_id &&
        toolBoxStore.deployMode === 'onpremise' &&
        auth.isLoggedIn()
      ) {
        if (!user.authorized_ngw.includes(toolData.ngw_client_id)) {
          // window location assign
          serverApi.ngwClientAuthrizeInTool(
            toolData.operation_id,
            toolData.ngw_client_id
          );
        }
      }

      try {
        const respInputs = await serverApi.getToolInputs(id);
        const respCode = await serverApi.getToolCode(id);

        if (!respInputs.data || !respCode.data.data) {
          showNotification(t('operation.otherError'), '', CloseOutlined);
        } else {
          setInputs(respInputs.data);
          setCodeText(respCode.data.data);
          setLoading(false);
        }
      } catch (error) {
        showNotification(t('operation.otherError'));
        setLoading(false);
        console.log(error);
      }
    };

    fetchToolInnerData(toolId as string);

    if (searchParams.get('tab') === 'code') {
      setContentKey('code');
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolId, searchParams, auth, user.authorized_ngw, serverApi, t]); // needed to update only  when toolId is new

  const data = toolData ? normalizeToolData(toolData) : placeholderTool;

  const handleRadio = ({ target: { value } }: RadioChangeEvent) => {
    setContentKey(value);
    setSearchParams((params) => {
      params.set('tab', value);
      return params;
    });
  };

  const handleSetFavorite = async (newFavStatus: boolean) => {
    if (toolData) {
      try {
        const res = await serverApi.postSetFavorite(
          toolData.operation_id,
          newFavStatus
        );

        if (toolBoxStore.deployMode !== 'onpremise') {
          sendYmEvent(newFavStatus ? 'addFavorite' : 'removeFavorite');
        }

        if (res.data.status === 'updated') {
          toolBoxStore.setFavorite(toolData.operation_id, newFavStatus);
        } else {
          throw new Error();
        }
      } catch (error) {
        showNotification(t('toolCard.favAddError'), '', CloseOutlined);
      }
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '0 10px 0 10px',
      }}
    >
      {loading ? (
        <SpinnerPage />
      ) : (
        <div
          style={{
            flexGrow: 1,
            maxWidth: '1100px',
            paddingTop: '40px',
            lineHeight: 1.6,
          }}
        >
          <h1
            className="tool-header"
            style={{
              margin: 0,
              fontSize: 34,
              position: 'relative',
            }}
          >
            {data.name}{' '}
            {data.is_dev && (
              <Tag style={{ bottom: '6px' }} color="#e91e63">
                DEV
              </Tag>
            )}
            {data.is_free && (
              <Tag style={{ bottom: '6px' }} color="#0070c5">
                FREE
              </Tag>
            )}
            {auth.isLoggedIn() &&
              (toolData?.is_favorite ? (
                <Tooltip
                  style={{ position: 'relative' }}
                  title={t('toolCard.removeFromFav')}
                  mouseEnterDelay={0.8}
                >
                  <Button
                    className="fav-button-fav-tool"
                    type="text"
                    shape="circle"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleSetFavorite(false);
                    }}
                  />
                </Tooltip>
              ) : (
                <Tooltip
                  style={{ position: 'relative' }}
                  title={t('toolCard.addToFav')}
                  mouseEnterDelay={0.8}
                >
                  <Button
                    className="fav-button"
                    type="text"
                    shape="circle"
                    onClick={async (e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      handleSetFavorite(true);
                    }}
                  />
                </Tooltip>
              ))}
          </h1>

          <div
            style={{
              fontSize: 16,
              fontWeight: 400,
              fontFamily: 'Ubuntu, Roboto, Helvetica, Arial, sans-serif',
              display: 'flex',
              alignItems: 'baseline',
              gap: '6px',
            }}
          >
            <div>
              <span> {data.description} </span>
              {toolBoxStore.appMode.deploy === 'cloud' && (
                <a href={data.link} target="_blank" rel="noreferrer">
                  {t('operation.details')}
                </a>
              )}
            </div>
          </div>

          <Card
            style={{ marginTop: '6px' }}
            bordered={true}
            styles={{ body: { padding: '16px' } }}
          >
            <Radio.Group
              buttonStyle="solid"
              onChange={handleRadio}
              value={contentKey}
              style={{}}
            >
              <Radio.Button value="form">{t('operation.form')}</Radio.Button>
              <Radio.Button value="code">{t('operation.code')}</Radio.Button>
            </Radio.Group>

            <div className={contentKey === 'form' ? '' : 'hidden'}>
              <OperationForm
                inputs={inputs}
                toolId={toolId}
                // isEnabled={runEnabled}
              />
            </div>

            <div className={contentKey === 'code' ? '' : 'hidden'}>
              <OperationCode code={codeText} />
            </div>
          </Card>
        </div>
      )}
    </div>
  );
});

export { Operation };
