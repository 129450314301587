import './infoBlock.css';
import React from 'react';
import { Carousel, Grid } from 'antd';

import { ApiOutlined } from '@ant-design/icons';
import LanguageIcon from '@mui/icons-material/Language';
import MapIcon from '@mui/icons-material/Map';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import ManageHistoryIcon from '@mui/icons-material/ManageHistory';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import ArchitectureOutlinedIcon from '@mui/icons-material/ArchitectureOutlined';

import { useTranslation } from 'react-i18next';

import { sendYmEvent } from '../utils/metrics';

const { useBreakpoint } = Grid;

const NarrowView: React.FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <div
      style={{
        backdropFilter: 'blur(4px)',
        backgroundColor: 'rgba(255, 255, 255, 0.55)',
      }}
      onClick={() => {
        sendYmEvent('narrowInfoBlockClick');
      }}
    >
      <Carousel
        draggable
        arrows
        style={{
          width: '80%',
          marginRight: 'auto',
          marginLeft: 'auto',
        }}
      >
        <div className="point-slide">
          <h1 style={{ margin: '0', color: '#0070c5', lineHeight: 1.3 }}>
            NextGIS Toolbox
          </h1>
          <p
            style={{
              marginTop: '16px',
              fontSize: '16px',
              fontWeight: 500,
              color: 'rgba(0, 0, 0, 0.88)',
            }}
          >
            {t('info.header')}
          </p>
        </div>

        <div className="point-slide">
          <MapIcon style={{ fontSize: 48, color: 'rgb(0, 112, 197)' }} />
          <div>
            <h3 style={{ margin: '0px 0px 0px 0px' }}>{t('info.thesis1')}</h3>
          </div>
        </div>

        <div className="point-slide">
          <ImportContactsIcon
            style={{ fontSize: 48, color: 'rgb(0, 112, 197)' }}
          />
          <div>
            <h3 style={{ margin: '0px 0px 0px 0px' }}>{t('info.thesis2')}</h3>
          </div>
        </div>

        <div className="point-slide">
          <LanguageIcon style={{ fontSize: 48, color: 'rgb(0, 112, 197)' }} />

          <div>
            <h3 style={{ margin: '0px 0px 0px 0px' }}>{t('info.thesis3')}</h3>
          </div>
        </div>

        <div className="point-slide">
          <ManageHistoryIcon
            style={{ fontSize: 48, color: 'rgb(0, 112, 197)' }}
          />
          <div>
            <h3 style={{ margin: '0px 0px 0px 0px' }}>{t('info.thesis4')}</h3>
          </div>{' '}
        </div>

        <div className="point-slide">
          <SupportAgentIcon
            style={{ fontSize: 48, color: 'rgb(0, 112, 197)' }}
          />

          <div>
            <h3 style={{ margin: '0px 0px 0px 0px' }}>{t('info.thesis5')}</h3>
          </div>
        </div>

        <div className="point-slide">
          {i18n.language === 'ru' ? (
            <ArchitectureOutlinedIcon
              style={{ fontSize: 48, color: 'rgb(0, 112, 197)' }}
            />
          ) : (
            <ApiOutlined style={{ fontSize: 48, color: 'rgb(0, 112, 197)' }} />
          )}

          <div>
            <h3 style={{ margin: '0px 0px 0px 0px' }}>{t('info.thesis6')}</h3>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

const FullView: React.FC = () => {
  const { t, i18n } = useTranslation();
  const screens = useBreakpoint();

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '14px 10px 0 10px',
        gap: '40px',
      }}
      onClick={() => {
        sendYmEvent('fullInfoBlockClick');
      }}
    >
      <div>
        <div
          className="main-header-card"
          style={{
            margin: '14px 0px 14px 0px',
            width: '438px',
            padding: '12px',
            backgroundColor: 'rgb(255, 255, 255, 0.75)',
            backdropFilter: 'blur(4px)',
            borderRadius: '12px',
          }}
        >
          <div
          // style={{ display: 'flex' }}
          >
            <h1 style={{ margin: '0', color: '#0070c5', lineHeight: 1.3 }}>
              NextGIS Toolbox
            </h1>
            <span
              style={{
                marginTop: '2px',
                fontSize: '20px',
                fontWeight: 500,
                whiteSpace: 'pre-line',
                color: 'rgba(0, 0, 0, 0.88)',
              }}
            >
              {t('info.header')}
            </span>
          </div>
        </div>
      </div>

      <div
        style={{
          maxWidth: '1200px',
          padding: '14px',
          borderRadius: '12px',
          display: 'grid',
          justifyContent: 'center',
          gridTemplateColumns: screens.xxl ? '1fr 1fr 1fr' : '1fr 1fr',
          gridTemplateRows: screens.xxl ? '1fr 1fr' : '1fr 1fr 1fr',
          gap: '10px',
          // minHeight: '380px',
        }}
      >
        <div className="point-card">
          <MapIcon style={{ fontSize: 48, color: 'rgb(0, 112, 197)' }} />
          <div>
            <h4 style={{ margin: '0px 0px 0px 0px' }}>{t('info.thesis1')}</h4>
          </div>
        </div>

        <div className="point-card">
          <ImportContactsIcon
            style={{ fontSize: 48, color: 'rgb(0, 112, 197)' }}
          />
          <div>
            <h4 style={{ margin: '0px 0px 0px 0px' }}>{t('info.thesis2')}</h4>
          </div>
        </div>

        <div className="point-card">
          <LanguageIcon style={{ fontSize: 48, color: 'rgb(0, 112, 197)' }} />

          <div>
            <h4 style={{ margin: '0px 0px 0px 0px' }}>{t('info.thesis3')}</h4>
          </div>
        </div>

        <div className="point-card">
          <ManageHistoryIcon
            style={{ fontSize: 48, color: 'rgb(0, 112, 197)' }}
          />

          <div>
            <h4 style={{ margin: '0px 0px 0px 0px' }}>{t('info.thesis4')}</h4>
          </div>
        </div>

        <div className="point-card">
          <SupportAgentIcon
            style={{ fontSize: 48, color: 'rgb(0, 112, 197)' }}
          />

          <div>
            <h4 style={{ margin: '0px 0px 0px 0px' }}>{t('info.thesis5')}</h4>
          </div>
        </div>

        <div className="point-card">
          {i18n.language === 'ru' ? (
            <ArchitectureOutlinedIcon
              style={{ fontSize: 48, color: 'rgb(0, 112, 197)' }}
            />
          ) : (
            <ApiOutlined style={{ fontSize: 48, color: 'rgb(0, 112, 197)' }} />
          )}

          <div>
            <h4 style={{ margin: '0px 0px 0px 0px' }}>{t('info.thesis6')}</h4>
          </div>
        </div>
      </div>
    </div>
  );
};

const InfoBlock: React.FC<{ show: boolean }> = ({ show }) => {
  const screens = useBreakpoint();

  return (
    <div
      className={show ? 'container-shown' : 'container-hidden'}
      style={{
        position: 'relative',
        zIndex: 20,
        boxShadow: 'rgba(0, 0, 0, 0.05) 0px 4px 4px',
        background: `url('/static/big_pic_dark_10.png') center center`,
      }}
    >
      {!screens.xl ||
      screens.xs ||
      (screens.sm && !screens.md) ||
      (screens.sm && screens.md && !screens.lg) ? (
        <NarrowView />
      ) : (
        <FullView />
      )}
    </div>
  );
};

export { InfoBlock };
