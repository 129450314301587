import './ToolCard.css';
import React, { useState } from 'react';

import { Button, Card, Tag, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import { appRoutes } from '../routes/appRoutes';

import { normalizeToolData } from '../helpers';

import { useNavigate } from 'react-router';
import { useAuth } from '../providers/AuthProvider';
import { useApi } from '../providers/ServerApiProvider';

import { observer } from 'mobx-react-lite';
import { toolBoxStore } from '../store/store';

import { useTranslation } from 'react-i18next';
import { showNotification } from '../customNotifications';

import type { ToolInfo } from '../store/store';

import { sendYmEvent } from '../utils/metrics';

const CardTitle: React.FC<{ tool: ToolInfo }> = observer(({ tool }) => {
  const { t } = useTranslation();
  const auth = useAuth();
  const serverApi = useApi();

  const [favPending, setFavPending] = useState(false);

  const tags = toolBoxStore.tagsList.filter((tag) =>
    tool.tags.includes(tag.id)
  );

  if (tool.is_dev) {
    tags.unshift({ id: 8888888, name: 'DEV', color: '#e91e63' });
  }
  if (tool.is_free) {
    tags.unshift({ id: 9999999, name: 'FREE', color: '#0070c5' });
  }

  const handleSetFavorite = async (newFavStatus: boolean) => {
    setFavPending(true);
    try {
      const res = await serverApi.postSetFavorite(
        tool.operation_id,
        newFavStatus
      );

      if (toolBoxStore.deployMode !== 'onpremise') {
        sendYmEvent(newFavStatus ? 'addFavorite' : 'removeFavorite');
      }

      if (res.data.status === 'updated') {
        toolBoxStore.setFavorite(tool.operation_id, newFavStatus);
      }

      if (res.status !== 200) {
        throw new Error();
      }
    } catch (error) {
      showNotification(t('toolCard.favAddError'), '', CloseOutlined);
    }
    setFavPending(false);
  };

  return (
    <div style={{ alignSelf: 'flex-start' }}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <div
          style={{
            alignSelf: 'flex-start',
            whiteSpace: 'pre-wrap',
            lineHeight: 1.4,
          }}
        >
          {tags.map((item) => (
            <Tag
              key={item.id}
              bordered={true}
              color={item.color || 'blue'}
              style={{ fontWeight: 500 }}
            >
              {item.name}
            </Tag>
          ))}
        </div>
        {auth.isLoggedIn() &&
          (tool.is_favorite ? (
            <Tooltip title={t('toolCard.removeFromFav')} mouseEnterDelay={0.8}>
              <Button
                className="fav-button-fav-tool"
                type="text"
                shape="circle"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleSetFavorite(false);
                }}
              />
            </Tooltip>
          ) : (
            <Tooltip title={t('toolCard.addToFav')} mouseEnterDelay={0.8}>
              <Button
                disabled={favPending}
                className="fav-button"
                type="text"
                shape="circle"
                onClick={async (e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  handleSetFavorite(true);
                }}
              />
            </Tooltip>
          ))}
      </div>
      <span
        style={{
          fontWeight: 600,
          whiteSpace: 'pre-line',
          letterSpacing: '-0.5px',
          lineHeight: '28px',
        }}
      >
        {tool.name}
      </span>
    </div>
  );
});

const CardBody: React.FC<{ tool: ToolInfo }> = observer(({ tool }) => {
  const i = normalizeToolData(tool);

  return (
    <>
      <div
        style={{
          display: '-webkit-box',
          WebkitLineClamp: 4,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
        }}
      >
        {i.description}
      </div>
    </>
  );
});

const ToolCard: React.FC<{ tool: ToolInfo }> = ({ tool }) => {
  const i = normalizeToolData(tool);
  const navigate = useNavigate();

  return (
    <a
      className="tool-link"
      style={{ height: '100%' }}
      href={`${appRoutes.operation}/${tool.operation_id}`}
      onKeyDown={(e) => {
        if (e.code === 'Enter') {
          navigate(`${appRoutes.operation}/${tool.operation_id}`);
        }
      }}
      onClick={(e) => {
        e.preventDefault();
        navigate(`${appRoutes.operation}/${tool.operation_id}`);
      }}
    >
      <Card
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '160px',
          height: '100%',
          userSelect: 'auto',
        }}
        key={i.id}
        bordered={false}
        hoverable={true}
        styles={{
          header: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            minHeight: '32px',
            padding: '8px 16px 0px 16px',
            margin: 0,
            borderBottom: 'none',
          },
          body: {
            flexGrow: 10,
            flexShrink: 10,
            height: '80%',
            padding: '10px 16px 16px 16px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          },
        }}
        title={<CardTitle tool={tool} />}
      >
        <CardBody tool={tool} />
      </Card>
    </a>
  );
};
export { ToolCard };
