import axios from 'axios';
import { toolInput } from '../interfaces';

export const isInputPassword = (input: toolInput) => {
  const passwordFieldsList = ['ngw_pas'];

  return (
    input.name.includes('password') || passwordFieldsList.includes(input.name)
  );
};

export const normalizeStringForSearch = (string: string) => {
  return string.toLowerCase().replaceAll('ё', 'е').trim();
};

export const isUrl = (val: string) =>
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    val
  );

export const convertToHash = (string: string) => {
  const utf8 = new TextEncoder().encode(string);
  return crypto.subtle.digest('SHA-256', utf8).then((hashBuffer) => {
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((bytes) => bytes.toString(16).padStart(2, '0'))
      .join('');
    return hashHex;
  });
};

export const getAvatarUrl = async (email: string): Promise<string | null> => {
  const hash = await convertToHash(email);
  const resp = await axios.get(`https://gravatar.com/${hash}.json`, {
    validateStatus: null,
  });
  if (resp.status === 200) {
    return `https://gravatar.com/avatar/${hash}`;
  } else {
    return null;
  }
};
